/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";
import { MusicSection } from "../sections/Music";
import { useHideWindow, useScreenWidth } from "../../../hooks";
import { Letter } from "../components/Letter";
import { useState } from "react";
import { VideoWindow } from "../../../components/VideoWindow";
import { Footer } from "../../../components";
import { Televisions } from "../components/Televisions";
import { WebLinks } from "../components/WebLinks";
import { KyrieImages } from "../components/KyrieImages";
import { AboutText } from "../components/AboutText";
import { KyrieImage } from "../components/KyrieImage";
import { RainbowDivider } from "../components/RainbowDivider";
import { Music } from "src/somethings";
import { PlaylistEmbed } from "../components/PlaylistEmbed";

export const MobileLayout = ({
  selectedAlbum,
  setHideWindow,
  setSelectedAlbum,
}: {
  selectedAlbum?: Music;
  setHideWindow: (hideWindow: boolean) => void;
  setSelectedAlbum: (selectedAlbum?: Music) => void;
}) => {
  const width = useScreenWidth();
  return (
    <div css={mainContent(width)}>
      <AboutText />
      <KyrieImage />
      <Letter />
      <KyrieImages />
      <MusicSection
        selectedAlbum={selectedAlbum}
        setSelectedAlbum={(album) => {
          setSelectedAlbum(album);
          setHideWindow(true);
        }}
      />
      <RainbowDivider />
      <Televisions />
      <RainbowDivider />
      <PlaylistEmbed />
      <WebLinks />
      <img
        src="/images/eclipsegif.gif"
        css={css`
          width: 50%;
        `}
      />
    </div>
  );
};

const mainContent = (width: number) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${width}px;
  height: 100%;
`;
