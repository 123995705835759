import { useScreenWidth } from "../hooks";
import { getIframeId } from "../utils";
import {
  breakpointVals,
  IFRAME_SIZES_VALUES,
  WEBSITE_IFRAME_SIZES_VALUES,
} from "../constants";
import { ReactEventHandler } from "react";
import { ContentType } from "src/somethings";

export const Iframe = ({
  link,
  scaleDown,
  onLoad,
  contentType,
}: {
  link?: string;
  scaleDown?: number;
  onLoad?: ReactEventHandler<HTMLIFrameElement>;
  contentType?: ContentType;
}) => {
  const width = useScreenWidth();
  const id = getIframeId(link || "");

  const getDimensions = (
    dimension: "width" | "height",
    contentType = "video"
  ) => {
    if (width > 0 && width < breakpointVals.tabletSm) {
      if (contentType === "website") {
        return WEBSITE_IFRAME_SIZES_VALUES["mobile"][dimension];
      }
      return IFRAME_SIZES_VALUES["mobile"][dimension];
    }
    if (width > 0 && width < breakpointVals.desktopSm) {
      if (contentType === "website") {
        return WEBSITE_IFRAME_SIZES_VALUES["tablet"][dimension];
      }
      return IFRAME_SIZES_VALUES["tablet"][dimension];
    }
    if (contentType === "website") {
      return WEBSITE_IFRAME_SIZES_VALUES["desktop"][dimension];
    }
    return IFRAME_SIZES_VALUES["desktop"][dimension];
  };

  const props =
    contentType === "website"
      ? { src: link }
      : {
          src: `https://www.youtube.com/embed/${id}`,
          title: "YouTube video player",
          frameBorder: "0",
          allow:
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
          referrerPolicy: "strict-origin-when-cross-origin" as const,
          allowFullScreen: true,
        };

  return (
    <iframe
      width={
        scaleDown
          ? getDimensions("width", contentType) / scaleDown
          : getDimensions("width", contentType)
      }
      height={
        scaleDown
          ? getDimensions("height", contentType) / scaleDown
          : getDimensions("height", contentType)
      }
      onLoad={onLoad}
      {...props}
    />
  );
};
