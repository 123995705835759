export const homeCopy = {
  answerComputerWindow: {
    question: "Would you like to see something cool?",
    answers: ["Yes", "No", "Not sure"],
  },
  noResponse: {
    image: { src: "/images/sadkittengif.gif", alt: "Sad kitten" },
    text: "That's a shame. But you can always ",
    buttonText: "change your mind",
  },
  notSureResponse: {
    notSureButton: "I'm still not sure",
    readyButton: "I'm ready to decide",
  },
};
