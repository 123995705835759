/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { breakpointVals } from "src/constants";
import { useScreenSize, useScreenWidth } from "src/hooks";
import { getMediaQuery } from "src/utils";
export const PlaylistEmbed = () => {
  const width = useScreenWidth();
  return (
    <div
      css={css`
        padding: 20px;
        width: calc(${width}px - 40px);
        ${getMediaQuery("tabletSm")} {
          width: calc(${width / 2.2}px - 40px);
        }
        display: flex;
        justify-content: center;
        ${getMediaQuery("desktopLg")} {
          padding: 20px;
        }
      `}
    >
      <iframe
        style={{ borderRadius: "12px" }}
        src="https://open.spotify.com/embed/playlist/6yNhNn8Z2YwHe6QNjfBzVa?utm_source=generator"
        width="100%"
        height={width < breakpointVals.desktopMd ? 400 : 450}
        frameBorder="0"
        // allowFullScreen={}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      />
    </div>
  );
};
