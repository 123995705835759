/** @jsxImportSource @emotion/react */
import { useScreenSize } from "../hooks";
import { DraggableComputerWindow } from ".";
import { useScrollPosition } from "src/hooks/useScrollPosition";
import { Music, Something, Video } from "src/somethings";
import { useMemo } from "react";

export const VideoWindow = ({
  something,
  showYear,
  showArtistName,
  onCloseClick,
  xOffset,
}: {
  something: Music | Video;
  showYear?: boolean;
  showArtistName?: boolean;
  onCloseClick: () => void;
  xOffset?: number;
}) => {
  const scrollPosition = useScrollPosition();
  const screenSize = useScreenSize();
  const y = 100 + scrollPosition;

  const coords = useMemo(() => {
    const defaultCoords = {
      mobileSm: { x: 50, y },
      mobileLg: { x: 100, y },
      tabletSm: { x: 180, y },
      tabletMd: { x: 250, y },
      tabletLg: { x: 350, y },
      desktopSm: { x: 400, y },
      desktopMd: { x: 500, y },
      desktopLg: { x: 600, y },
    };

    const getCoords = () => {
      if (xOffset) {
        return Object.assign(
          {},
          ...Object.entries(defaultCoords).map(([key, coord]) => {
            const newX = coord.x + xOffset;
            return {
              [key]: { ...coord, x: newX },
            };
          })
        );
      }
    };

    if (xOffset) {
      return getCoords();
    }
    return defaultCoords;
  }, [xOffset]);

  const isMusic = (something: Music | Video): something is Music =>
    Object.hasOwn(something, "artist");

  const getTitle = () => {
    if (isMusic(something)) {
      if (Object.hasOwn(something, "year")) {
        if (showYear && showArtistName) {
          return `${something.title} - ${something.artist} (${something.year})`;
        }
        if (showYear) {
          return `${something.title} (${something.year})`;
        }
      }
      if (showArtistName) {
        return `${something.title} - ${something.artist}`;
      }
    }
    return something.title;
  };

  return (
    <DraggableComputerWindow
      defaultPosition={coords[screenSize]}
      something={{
        ...something,
        contentType: "video",
        title: getTitle(),
      }}
      onCloseClick={onCloseClick}
    />
  );
};
