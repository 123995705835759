/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMemo } from "react";
import { SONGS } from "src/constants";
import { SongTag } from "src/somethings";
import { getMediaQuery } from "src/utils";
import { useNav } from "../../../NavProvider";

export const NowPlayingBar = ({ songPlaying }: { songPlaying: SongTag }) => {
  const { menuIsOpen } = useNav();
  const nowPlayingText = useMemo(() => {
    return `Track 0${Object.values(SONGS).indexOf(songPlaying) + 1}:
    ${songPlaying} - Kyrie Halo - Eternity (2024)`;
  }, [songPlaying]);

  return (
    <div css={musicPlayerTopBar}>
      <div css={topBarText}>
        <div css={nowPlayingTextCss}>Now Playing:</div>
        <div css={songPlayingTextContainer}>
          <div css={scrollingSongPlayingText(menuIsOpen)}>{nowPlayingText}</div>
        </div>
      </div>
    </div>
  );
};

const musicPlayerTopBar = css`
  background-color: silver;
  color: black;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  ${getMediaQuery("tabletSm")} {
    padding-top: 0px;
  }
  ${getMediaQuery("desktopSm")} {
    padding-top: 10px;
  }
`;

const topBarText = css`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 25px;
  max-height: 25px;
  width: 100%;
  max-width: 100%;
`;

const nowPlayingTextCss = css`
  width: max-content;
  min-width: 80px;
`;

const songPlayingTextContainer = css`
  border-radius: 2px;
  overflow: hidden;
  height: 18px;
  background: rgba(222, 97, 149, 1);
  color: white;
  padding: 2px;
  width: calc(100% - 4px);
`;

const scrollingSongPlayingText = (menuIsOpen: boolean) => css`
  ${menuIsOpen &&
  css`
    position: relative;
    z-index: 0;
  `}
  width: max-content;
  overflow: hidden;
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
  /* for firefox */
  @-moz-keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for chrome */
  @-webkit-keyframes my-animation {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
`;
