/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Outlet } from "react-router-dom";
import { NavBar } from "src/components";
import { useNav } from "src/NavProvider";

export const Layout = () => {
  const { menuIsOpen, setMenuIsOpen } = useNav();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <NavBar />
      <div
        css={css`
          margin-top: ${menuIsOpen ? "250px" : "60px"};
        `}
      >
        <Outlet />
      </div>
    </div>
  );
};
