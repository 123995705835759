/** @jsxImportSource @emotion/react */
import "../../styles.css";
import { css } from "@emotion/react";
import { useHideWindow } from "../../hooks";
import { myMusic } from "src/data/myMusic";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Video } from "src/somethings";
import { VideoWindow } from "src/components/VideoWindow";
import { BasicWindow } from "src/components";
import { getMediaQuery } from "src/utils";
import { musicCopy } from "src/copy/musicCopy";
import { GoVideo } from "react-icons/go";
import parse from "html-react-parser";

export const Music = () => {
  const navigate = useNavigate();
  const [selectedSong, setSelectedSong] = useState<Video>();
  const { hideWindow, setHideWindow } = useHideWindow();
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      {selectedSong && (
        <div
          css={css`
            display: ${hideWindow ? "none" : "block"};
          `}
        >
          <VideoWindow
            something={selectedSong}
            onCloseClick={() => {
              setSelectedSong(undefined);
            }}
            showYear={true}
            xOffset={-50}
          />
        </div>
      )}

      <BasicWindow
        contentType="writing"
        hideWindow={!showInfoWindow}
        onCloseClick={() => setShowInfoWindow(false)}
      >
        <div
          css={css`
            text-align: justify;
            background-color: white;
            padding: 10px 20px 10px 20px;
          `}
        >
          {parse(musicCopy.mainInfo)}
        </div>
      </BasicWindow>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          width: max-content;
          gap: 10px;
          padding-top: 40px;
          width: 83%;
          max-width: 700px;
          ${getMediaQuery("desktopSm")} {
            max-width: 750px;
            gap: 20px;
          }
          font-family: "Rainy Hearts";
        `}
      >
        <div css={musicIcon} onClick={() => navigate("/ETERNITY")}>
          <img {...musicCopy.folderIcon} />
          <p>{musicCopy.folderIcon.text}</p>
        </div>
        {myMusic.map((song) => (
          <div
            css={musicIcon}
            onClick={() => {
              setSelectedSong(song);
              setHideWindow(true);
            }}
          >
            <img {...musicCopy.trackIcon} />
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
                width: 70px;
              `}
            >
              {song.hasVideo && (
                <div
                  css={css`
                    width: 10px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <GoVideo />
                </div>
              )}
              <div>{song.title.split("(")[0]}</div>
            </div>
          </div>
        ))}
        <div css={musicIcon} onClick={() => setShowInfoWindow(true)}>
          <img {...musicCopy.fileIcon} />
          <p>{musicCopy.fileIcon.text}</p>
        </div>
      </div>
    </div>
  );
};

const musicIcon = css`
  width: 70px;
  ${getMediaQuery("tabletMd")} {
    width: 80px;
  }
  ${getMediaQuery("tabletLg")} {
    width: 100px;
  }
  font-size: 12.5px;
  ${getMediaQuery("tabletSm")} {
    font-size: 14px;
  }
  ${getMediaQuery("tabletMd")} {
    font-size: 16px;
  }
  img {
    cursor: pointer;
    width: 60%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
