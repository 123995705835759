/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getMediaQuery } from "../../../utils";
import { MusicSection } from "../sections/Music";
import { useScreenWidth } from "../../../hooks";
import { Letter } from "../components/Letter";
import { useState } from "react";
import { VideoWindow } from "../../../components/VideoWindow";
import { Footer } from "../../../components";
import { Televisions } from "../components/Televisions";
import { WebLinks } from "../components/WebLinks";
import { KyrieImages } from "../components/KyrieImages";
import { AboutText } from "../components/AboutText";
import { KyrieImage } from "../components/KyrieImage";
import { RainbowDivider } from "../components/RainbowDivider";
import { breakpointVals } from "../../../constants";
import { Music } from "src/somethings";
import { PlaylistEmbed } from "../components/PlaylistEmbed";

export const DesktopLayout = ({
  selectedAlbum,
  setHideWindow,
  setSelectedAlbum,
}: {
  selectedAlbum?: Music;
  setHideWindow: (hideWindow: boolean) => void;
  setSelectedAlbum: (selectedAlbum?: Music) => void;
}) => {
  const width = useScreenWidth();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div css={mainContent(width)}>
        <div css={rightSide}>
          <AboutText />
          <KyrieImage />
          <Letter />
          <KyrieImages />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              ${getMediaQuery("desktopMd")} {
                flex-direction: row;
                align-items: center;
              }
            `}
          >
            <PlaylistEmbed />
            <div
              css={css`
                display: flex;
                justify-content: center;
                width: 100%;
                ${getMediaQuery("desktopMd")} {
                  width: 30%;
                }
              `}
            >
              <img
                src="/images/eclipsegif.gif"
                className="eclipse"
                css={css`
                  width: 30%;
                  ${getMediaQuery("desktopMd")} {
                    width: 100%;
                  }
                `}
              />
            </div>
          </div>
        </div>
        <div css={leftSide}>
          <MusicSection
            selectedAlbum={selectedAlbum}
            setSelectedAlbum={(album) => {
              setSelectedAlbum(album);
              setHideWindow(true);
            }}
          />
          <Televisions />
          <WebLinks />
        </div>
      </div>
    </div>
  );
};

const mainContent = (width: number) => css`
  display: flex;
  flex-direction: column;
  width: ${width}px;
  ${getMediaQuery("tabletSm")} {
    flex-direction: row-reverse;
    justify-content: center;
  }
  height: 100%;
`;

const rightSide = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  ${getMediaQuery("tabletSm")} {
    width: 45%;
  }
  ${getMediaQuery("tabletLg")} {
    width: 50%;
    padding: 20px;
  }
  ${getMediaQuery("desktopMd")} {
    width: 55%;
    padding: 40px;
  }
  ${getMediaQuery("desktopLg")} {
    width: 65%;
    padding: 50px;
  }
  font-family: sans-serif;
  color: white;
  font-size: 15px;
  text-align: justify;
`;

const leftSide = css`
  display: flex;
  flex-direction: column;
  ${getMediaQuery("tabletSm")} {
    width: 55%;
  }
  ${getMediaQuery("tabletLg")} {
    width: 45%;
  }
  ${getMediaQuery("desktopLg")} {
    width: 35%;
  }
  height: 100%;
`;

// const ConstellationDivider = () => (
//   <div
//     css={css`
//       width: 100%;
//       height: 230px;
//       background: no-repeat url("/images/constellationgif2.gif");
//       background-position: center;
//       background-color: black;
//     `}
//   >
//     <img src="/images/kissgif2.gif" />
//   </div>
// );
