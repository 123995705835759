/** @jsxImportSource @emotion/react */
import { getMediaQuery } from "../../../utils";
import { css } from "@emotion/react";
import { Albums } from "../components/Albums";
import { SoundSystem } from "../components/SoundSystem";
import { Music } from "src/somethings";

export const MusicSection = ({
  selectedAlbum,
  setSelectedAlbum,
}: {
  selectedAlbum?: Music;
  setSelectedAlbum: (album?: Music) => void;
}) => (
  <div css={mainContainer}>
    <div
      css={css`
        border: ridge 2px#4b3124;
        width: calc(100% - 4px);
      `}
    >
      <SoundSystem />
    </div>
    <div css={albumContainer} />
    <Albums {...{ selectedAlbum, setSelectedAlbum }} />
  </div>
);

const mainContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: inset 10px #955a3a;
  border-style: outset;
  background-color: #361902;
  width: calc(100% - 20px);
  height: 550px;
  ${getMediaQuery("tabletSm")} {
    height: 1440px;
  }
  ${getMediaQuery("desktopSm")} {
    height: 900px;
  }
  ${getMediaQuery("desktopLg")} {
    height: 1200px;
  }
`;

const albumContainer = css`
  background-color: #945c3e;
  border-top: ridge 2px #4b3124;
  border-bottom: ridge 2px #4b3124;
  width: calc(100% - 2px);
  height: 10px;
`;
