/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import {
  AnswerComputerWindow,
  DraggableComputerWindow,
} from "../../components";
import { Something } from "../../somethings";
import { useGetSomethings, useGetCoords, useScreenWidth } from "../../hooks";
import "../../styles.css";
import { css } from "@emotion/react";
import { getMediaQuery } from "src/utils";
import { indecisionQuotes } from "src/data/indecision";
import parse from "html-react-parser";
import { homeCopy } from "src/copy/homeCopy";

export const Home = () => {
  const coords = useGetCoords();
  const [answer, setAnswer] = useState<string>();
  const { somethingsToDisplay, getNewSomething } = useGetSomethings();
  const [itemAtFront, setItemAtFront] = useState<Something>();
  const [quote, setQuote] = useState<{
    quote: string;
    author: string;
  }>();

  const getRandomQuote = () => {
    let randomNumber = Math.floor(Math.random() * indecisionQuotes.length);
    let randomQuote = indecisionQuotes[randomNumber];
    while (randomQuote === quote) {
      randomQuote = getRandomQuote();
    }
    return randomQuote;
  };

  useEffect(() => {
    let randomQuote = getRandomQuote();
    setQuote(randomQuote);
  }, []);

  return (
    <>
      <div>
        {!answer && (
          <AnswerComputerWindow
            {...homeCopy.answerComputerWindow}
            setAnswer={setAnswer}
          />
        )}
      </div>
      <div>
        {answer === "Yes" &&
          somethingsToDisplay.map((something, i) => {
            if (!something) {
              return (
                <div>
                  You've done the unthinkable. I have not prepared for this.
                  Well, this was the extent of my preparation is what I mean.
                </div>
              );
            }
            return (
              <DraggableComputerWindow
                something={something}
                onCloseClick={() => {
                  getNewSomething(something);
                }}
                defaultPosition={coords[i]}
                onDrag={() => {
                  setItemAtFront(something);
                }}
                moveToFront={itemAtFront === something}
                key={`draggable-computer-window-${i}`}
              />
            );
          })}
        {answer === "No" && (
          <div>
            <img
              {...homeCopy.noResponse.image}
              css={css`
                width: 300px;
              `}
            />
            <p>
              {homeCopy.noResponse.text}
              <button
                css={css`
                  background-color: transparent;
                  border: none;
                  color: white;
                  padding: 0;
                  margin: 0;
                  cursor: pointer;
                  text-decoration: underline;
                `}
                onClick={() => setAnswer(undefined)}
              >
                {homeCopy.noResponse.buttonText}
              </button>
            </p>
          </div>
        )}
        {answer === "Not sure" && quote && (
          <div
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 20px;
              gap: 10px;
            `}
          >
            <div css={letterContainer}>
              <p>
                <i>{parse(quote.quote)}</i>
              </p>
              <p>{quote.author}</p>
            </div>
            <div
              css={css`
                display: flex;
                gap: 10px;
              `}
            >
              <button
                onClick={() => {
                  const randomQuote = getRandomQuote();
                  setQuote(randomQuote);
                }}
              >
                {homeCopy.notSureResponse.notSureButton}
              </button>
              <button onClick={() => setAnswer(undefined)}>
                {homeCopy.notSureResponse.readyButton}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const letterContainer = css`
  background: no-repeat url("/images/display/notepadsheet.png");
  padding: 10px 10px 10px 80px;
  color: black;
  a {
    color: black;
  }
  font-family: Times, "Times New Roman", serif;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  img {
    width: 50px;
  }
  font-weight: 540;
  max-width: 270px;
  ${getMediaQuery("desktopMd")} {
    width: 390px;
    max-width: 400px;
  }
`;
