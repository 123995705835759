import { eternityLinks } from "src/constants";
import { myLinks } from "src/data/links";

export const eternityCopy = {
  description:
    "'Eternity' is the circle that joins my love for pop melodies, shoegazey fuzz and digital noise. It's full of melodies that have been going round and round in my head for what feels like forever. <br/> It also took a really <i>really</i> long time to make.",
  linksSection: {
    heading: "Listen on:",
    streamingLinks: [
      { displayText: "Spotify", link: eternityLinks.streaming.spotify },
      { displayText: "Bandcamp", link: eternityLinks.streaming.bandcamp },
      { displayText: "YouTube", link: eternityLinks.streaming.bandcamp },
    ],
  },
  getPlaylistText: (link: string) =>
    `Listen to the <a href=${link}>Eternity DNA playlist</a>, featuring songs I love that inspired this E.P.`,
  answerComputerWindow: {
    question:
      "You've seen all the things that go with this song. I hope you liked them!",
    answers: ["Refresh items", "Choose a different song", "Go home"],
  },
};
