/** @jsxImportSource @emotion/react */
import { getMediaQuery } from "../../../utils";
import { css } from "@emotion/react";
import "../../../styles.css";
import { playlistLinks } from "../../../data/links";
import parse from "html-react-parser";
import { eternityCopy } from "src/copy/eternityCopy";

export const Description = () => (
  <div
    css={css`
      color: black;
      a {
        color: black;
      }
      padding-left: 5px;
      font-size: 14px;
      ${getMediaQuery("tabletSm")} {
        font-size: 16px;
        padding-top: 5px;
        padding-right: 5px;
      }
      background-color: silver;
      ${getMediaQuery("desktopSm")} {
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
      }
    `}
  >
    <div>{parse(eternityCopy.description)}</div>
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        ${getMediaQuery("tabletSm")} {
          padding-right: 0px;
        }
        ${getMediaQuery("desktopSm")} {
          flex-direction: column;
        }
      `}
    >
      <ul
        css={css`
          margin: 0px;
          padding: 0;
          li {
            margin-left: 15px;
          }
          width: 50%;
          ${getMediaQuery("tabletSm")} {
            width: 100px;
          }
          ${getMediaQuery("tabletMd")} {
            width: 50%;
          }
          ${getMediaQuery("desktopSm")} {
            width: 100%;
          }
          border: 3px dotted white;
          padding: 5px;
        `}
      >
        <strong>{eternityCopy.linksSection.heading}</strong>
        {eternityCopy.linksSection.streamingLinks.map(
          ({ displayText, link }) => (
            <li>
              <a href={link}>{displayText}</a>
            </li>
          )
        )}
      </ul>
      <div
        css={css`
          border: 3px dotted white;
          padding: 5px;
          height: 58px;
          ${getMediaQuery("tabletSm")} {
            height: 66px;
          }
          ${getMediaQuery("desktopSm")} {
            height: auto;
          }
          ${getMediaQuery("desktopSm")} {
            width: 100%;
          }
          width: 50%;
        `}
      >
        {parse(eternityCopy.getPlaylistText(playlistLinks.eternityDNA))}
      </div>
    </div>
  </div>
);
